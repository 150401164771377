<template>
  <div class="app-container">

    <el-tabs v-model="skActiveName" @tab-click="handleClickAll" type="border-card">
      <el-tab-pane label="税款统计" name="shuikuan">
      </el-tab-pane>
      <el-tab-pane label="学生税款统计" name="studentShuiKuan" v-if="this.userRoleName != '学生'">
      </el-tab-pane>
      <div class="app-container">
        <div class="top_btns clearfix">
          <div class="left_box">
            <div style="width:76%;margin-bottom:10px" v-if="skActiveName != 'shuikuan'">
              <search @success="init">
                <el-form style="margin:11px 0px 0px 15px;width:400px" class="styleForm">
                  <el-form-item label="选择班级 ：" :label-width="formLabelWidth" v-if="this.userRoleName != '学生'">   
                    <selectClass v-model:deptId="initList.deptId" @success="getUserList"></selectClass>
                  </el-form-item>
                  <el-form-item label="选择人员：" :label-width="formLabelWidth"  v-if="this.userRoleName != '学生'">
                    <selectuser ref="selectuser" code="bookkeeping" eduCode="" v-model:userId="initList.userIds" @success="getUser" style="margin-left:4px"></selectuser>
                  </el-form-item>
                </el-form>
              </search>
            </div>
            <qzf-search-period v-model:period="listQuery.period" @success="init()"></qzf-search-period>
            {{checkName}}
            <!-- <div class="total_box" style="display:inline-block">
              (<span>销项数量:{{1}} </span>
              <span>销项金额:{{2}} </span>
              <span>销项税额:{{3}} </span>
              <span>销项价税合计:{{4}} </span>)
            </div> -->
          </div>
          <div class="right_box">

          </div>
        </div>
        <div>
          <el-row>
            <el-col :span="17" style="position: relative;">
              <div class="status">
                <div v-if="listQuery.comId != 0 && comInfo.shStatus == 2">
                  <img src="@/assets/shtg.png">
                </div>
                <!-- 审核未通过 -->
                <div v-else-if="listQuery.comId != 0">
                  <img src="@/assets/wsh.png">
                </div>
              </div>
              <el-table stripe :data="list" style="width: 98%;height: 300px;" border  show-summary :summary-method="getSummaries" v-if="this.list.length != 0">
                <template #empty>
                  <el-empty :image-size="150" description="没有数据"></el-empty>
                </template>
                <el-table-column prop="name" label="申报列表名称" min-width="320">
                  <template #default="scope">
                    <span>{{$tableNameFilter(scope.row.tableName)}}</span>
                    <span style="margin-left:10px;color:#39b0d2;cursor:pointer" @click="declareDetail(scope.row)">申报详情</span>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="取数状态" align="center" min-width="90">
                  <div class="item_icon">
                    <i class="iconfont icon-duihao"></i>
                    <p>已取数</p>
                  </div>
                </el-table-column>
                <el-table-column label="申报状态" align="center" min-width="150">
                  <template #default="scope">
                    <el-tooltip placement="top" :disabled="!(scope.row.errLog + scope.row.businessLog)">
                      <template #content> {{scope.row.errLog + scope.row.businessLog}} </template>
                      <div class="item_icon">
                        <i :class="$batchSendIconSb(scope.row.taskStatus, scope.row.businessStatus)"></i>
                        <p>{{ $batchSendSb(scope.row.taskStatus, scope.row.businessStatus) }}</p>
                      </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="税负" align="center" min-width="70">
                  <template #default="scope">
                    <span>{{scope.row.sf}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="扣款状态" align="center" min-width="100">
                  <template #default="scope">
                    <el-tooltip placement="top" :disabled="!(scope.row.errLog2 + scope.row.businessLog2)">
                      <template #content> {{scope.row.errLog2 + scope.row.businessLog2}} </template>
                      <div class="item_icon">
                        <i :class="$batchSendIconSb(scope.row.taskStatus2, scope.row.businessStatus2)"></i>
                        <p>{{ $batchSendSb(scope.row.taskStatus2, scope.row.businessStatus2) }}</p>
                      </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="tax" label="税款" align="center" min-width="150">
                  <template #default="scope">
                    <span>{{scope.row.tax}}</span>
                    <span v-if="scope.row.tax_fjs"> + {{scope.row.tax_fjs}}</span>
                  </template>
                </el-table-column>
              </el-table>
              <el-table stripe :data="gsList" style="width: 98%;margin-top:20px;height: 300px;" border size="small" show-summary v-if="this.gsList.length != 0">
                <el-table-column prop="name" label="薪酬信息" min-width="320">
                  <template #default="scope">
                    <span>{{$tableNameFilter(scope.row.tableName)}}</span>
                    <span style="margin-left:10px;color:#39b0d2;cursor:pointer" @click="declareDetail(scope.row)">申报详情</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="name" label="申报人数" align="center" min-width="100">
                  <template #default="scope">
                    <span>{{scope.row.sf}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="工资/收入" align="center" min-width="100">
                  <template #default="scope">
                    <span>{{scope.row.sf}}</span>
                  </template>
                </el-table-column> -->
                <el-table-column prop="tax" label="个税金额" align="center" min-width="100">
                  <template #default="scope">
                    <span v-if="scope.row.tax">{{scope.row.tax}}</span>
                    <span v-else>{{0}}</span>

                  </template>
                </el-table-column>
                <el-table-column label="申报状态" align="center" min-width="100">
                  <template #default="scope">
                    <el-tooltip placement="top" :disabled="!(scope.row.errLog + scope.row.businessLog)">
                      <template #content> {{scope.row.errLog + scope.row.businessLog}} </template>
                      <div class="item_icon">
                        <i :class="$setUpStatusGsIconSb(scope.row.taskStatus, scope.row.businessStatus)"></i>
                        <p>{{ $setUpStatusGsSb(scope.row.taskStatus, scope.row.businessStatus) }}</p>
                      </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="name" label="开始时间" min-width="120" align="center">
                  <template #default="scope">
                    <span>{{scope.row.beginAt}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="结束时间" min-width="120" align="center">
                  <template #default="scope">
                    <span>{{scope.row.endAt}}</span>
                  </template>
                </el-table-column> -->
                <el-table-column prop="name" label="扣款状态" align="center" min-width="100">
                  <template #default="scope">
                    <el-tooltip placement="top" :disabled="!(scope.row.errLog2 + scope.row.businessLog2)">
                      <template #content> {{scope.row.errLog2 + scope.row.businessLog2}} </template>
                      <div class="item_icon">
                        <i :class="$setUpStatusGsIconSb(scope.row.taskStatus2, scope.row.businessStatus2)"></i>
                        <p>{{ $setUpStatusGsSb(scope.row.taskStatus2, scope.row.businessStatus2) }}</p>
                      </div>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="查看明细" align="center" min-width="90">
                  <template #default="scope">
                    <el-button size="small" @click="check(scope.row)">查看</el-button>
                  </template>
                </el-table-column>
              
              </el-table>
            </el-col>
            <el-col :span="7">
              <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card" style="height:100%">
                <el-tab-pane label="全部新增客户" name="first"></el-tab-pane>
                <el-tab-pane label="已审核" name="second"></el-tab-pane>
                <el-tab-pane label="未审核" name="third"></el-tab-pane>
                <div class="top_search">
                  <el-input size="small" style="width: 70%;" v-model="initList.name"></el-input>
                  <el-button type="primary" size="small" @click="init"  >
                    <el-icon><Search /></el-icon><span  > 搜索</span>
                  </el-button>
                </div>
                <div class="center_main" :style="{height: contentStyleRight}">
                  <div
                    v-for="(item,index) in data"
                    :key="index"
                    @click="getList(item.id)" :class="{curs:this.listQuery.comId == item.id}"
                  >{{item.name}}</div>
                </div>
                </el-tabs>
            </el-col>
          </el-row>
          
        </div>
        <div style="margin-top:20px">
          <el-button v-if="listQuery.comId && comInfo.shStatus == 2" size="small" type="danger" @click="shenHe(9)" plain>
            <el-icon><Stamp /></el-icon><span  > 反审核</span>
          </el-button>
          <el-button v-else-if="listQuery.comId" size="small" type="danger" @click="shenHe(2)" plain>
            <el-icon><Stamp /></el-icon><span  > 审核</span>
          </el-button>
        </div>
      </div>
    </el-tabs>
  </div>
  <declareDetails ref="declareDetails"></declareDetails>
</template>

<script>
import { taxList } from "@/api/taxCalculation"
import { eaCompanyList, toExamine } from "@/api/company"
import { currentAccountPeriod } from '@/utils'
import declareDetails from "./components/declareDetails"
export default {
  name: "taxStatistics",
  components:{
    declareDetails
  },
  data(){
    return{
      comInfo: {
        shStatus: 0,
      },
      listQuery:{
        period:currentAccountPeriod(),
        comId:'',
      },
      initList:{
        status:'2',
        shStatus: 0,
        name: '',
        userIds:[this.$store.getters['user/user'].userId]
      },
      optionList:[],
      list:[],
      gsList:[],
      data:[],
      skActiveName: 'shuikuan',
      sh_status: true,
      checkName:'',
      userRoleName:this.$store.getters['user/user'].userRoleName,
      activeName: "first",
    }
  },
  created(){
    this.init()
    this.contentStyleObj= this.$getHeight(700)
    this.contentStyleRight=this.$getHeight(412)
  },
  deactivated () {
     if(!this.$getCachedViews("taxStatistics")){//销毁 mitt bus
       
     }
  },
  methods:{
    init(){
      this.initList.period = this.listQuery.period
      eaCompanyList(this.initList).then(res=>{
        this.data = res.data.data.list
        this.getList()
      })
    },
    handleClickAll(tab){
      this.initList.userIds = []
      if (tab.props.label == "学生税款统计") {
        
      } else if (tab.props.label == "税款统计") {
        this.initList.userIds = [this.$store.getters['user/user'].userId]
      }
      this.init()
    },
    getList(id){
      if(id){
        this.listQuery.comId = Number(id)
        this.data.map(v => {
          if(this.listQuery.comId == v.id){
            this.checkName = v.name
          }
        })
      }else {
        this.listQuery.comId = 0
      }
      let comInfos = this.data.filter(v=>{
        return id == v.id
      })
      if(comInfos.length != 0){
        this.comInfo = comInfos[0]
      }
      taxList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.list =  res.data.data.list.filter(v=>{
            return v.tableName != "gs_gs" && v.tableName != "gs_gs_one"
          })
          this.gsList =  res.data.data.list.filter(v=>{
            return v.tableName == "gs_gs" || v.tableName == "gs_gs_one"
          })
        }else{
          this.list = []
          this.gsList = []
        }
      })
    },
    getSummaries(param){
      let { columns, data } = param;
      let taxTotal = 0
      let vatFjsTotal = 0
      let sumTotal = 0
      data = this.list
      data.map(v=>{
        taxTotal += Number(v.tax)
        if(v.tax_fjs){
          vatFjsTotal += Number(v.tax_fjs)
        }
        sumTotal = taxTotal + vatFjsTotal
      })
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }if(column.property == "tax"){
          if(sumTotal){
            sums[index] = sumTotal.toFixed(2)
          } else{
            sums[index] = 0
          }
        }else {
          sums[index] = '--';
        }
      });
      return sums;
    },
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,e.taskName)
    },
    handleClick(tab){
     // this.comId = this.$options.data().comId
      if(this.skActiveName == 'shuikuan'){
        this.initList.userIds = []
      }else{
        this.initList.userIds = [this.$store.getters['user/user'].userId]
      }
      if(tab.props.name == "first"){
        this.initList.shStatus = 0
        this.comInfo.shStatus = 0
      }else if (tab.props.name == "second"){
        this.initList.shStatus = 2
        this.comInfo.shStatus = 2
      }else if (tab.props.name == "third"){
        this.initList.shStatus = 9
        this.comInfo.shStatus = 9 
      }
      this.init()
    },
    // 审核
    shenHe(e) {
      this.comInfo.shStatus = e
      let param = {
        shStatus: this.comInfo.shStatus,
        period: this.listQuery.period,
        comId: this.listQuery.comId
      }
      toExamine(param).then(res => {
        if(res.data.msg == 'success') {
          this.$qzfMessage('成功',3)
          this.init()
        }
      })
    },

    getUserList(){
      this.$refs.selectuser.init(this.initList.deptId)
    },
    getUser(e){
      this.initList.userIds = []
      if(this.initList.userIds.length == 0){
        e.map(v => {
          this.initList.userIds.push(v.id)
        })
      } 
    }
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 10px;
   .left_box{
    float: left;
    // width: 56%;
  }
  .right_box {
    float: right;
    text-align: right;
    .status {
      position: absolute;
      top: 20px;
      right: 360px;
    }
  }
}
.styleForm{
  .el-select{
    margin-left: 0px;
  }
}
.total_box{
  margin-left: 15px;
  p{
    display: inline-block;
    font-size: 15px;
    color: #333;
    line-height: 24px;
  }
  span{
    color: #666;
    font-size: 14px;
    line-height: 24px;
  }
}
.status{
  position: absolute;
  right: 0px;
  top:-30px;
  z-index: 99;
  img{
    width:100px;
  }
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
// .center_main{
//   width: 100%;
//   height:100%;
//   margin: 0 auto;
//   line-height: 32px;
//   margin-top: 20px; 
// }
.center_main {
  width: 100%;
  left:0;
  margin: 0 auto;
  line-height: 36px;
  // margin-top: 20px;
  padding: 15px;
  overflow-y: auto;
  // height:410px;
  div{
    cursor:pointer;
    transition: all .4s;
    font-size: 14px;
    line-height: 36px;
    border-bottom: 1px solid #eee;
  }
  div:hover{
    color: #17a2b8;
  }
  .cur{
    color: #17a2b8
  }
}
.top_search{
  width: 100%;
  z-index: 99 !important;
}
.curs{
  color: #17a2b8 !important;
}
</style>